/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-15 17:43:04
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import { auditStatus, getLabel, lookStatus, jobTitle } from '@/utils/options';

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 10,
    kname: 'name',
    itemProps: {
      label: '医生姓名：' },

    component: {
      name: 'text' } },


  {
    span: 10,
    kname: 'image',
    itemProps: {
      label: '头像：' },

    component: {
      name: 'c-image' } },


  {
    span: 10,
    kname: 'phone',
    itemProps: {
      label: '手机号码：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'approvalStatus',
    itemProps: {
      label: '状态：' },

    component: {
      name: 'text',
      textStyle: {
        fontWeight: 'bold',
        color: '#618CFF' } },


    valueFilter: function valueFilter(v) {
      return getLabel(auditStatus, v);
    } },

  {
    span: 10,
    kname: 'recommendCode',
    itemProps: {
      label: '推荐手机号：' },

    component: {
      name: 'text' } },


  {
    span: 10,
    kname: 'recommendName',
    itemProps: {
      label: '推荐人姓名：' },

    component: {
      name: 'text' } },


  {
    span: 10,
    kname: 'birthday',
    itemProps: {
      label: '生日：' },

    component: {
      name: 'text' } },


  // {
  //   span: 6,
  //   kname: 'departmentName',
  //   itemProps: {
  //     label: '科室名称：'
  //   },
  //   component: {
  //     name: 'text'
  //   }
  // },
  // {
  //   span: 6,
  //   kname: 'jobTitle',
  //   itemProps: {
  //     label: '职称：'
  //   },
  //   component: {
  //     name: 'text'
  //   },
  //   valueFilter: (v)=>{
  //     return getLabel(lookStatus, v)
  //   }
  // },
  // {
  //   span: 10,
  //   kname: 'baseArea',
  //   itemProps: {
  //     label: '位置：',
  //     rules: [genRules('require')],
  //     prop: 'baseArea'
  //   }
  // },
  // {
  //   span: 6,
  //   kname: 'applicationTime',
  //   itemProps: {
  //     label: '申请时间：'
  //   },
  //   component: {
  //     name: 'text'
  //   }
  // },
  {
    span: 10,
    kname: 'integral',
    itemProps: {
      label: '积分：',
      prop: 'integral' },

    component: {} },



  // {
  //   span: 6,
  //   kname: 'hospitalName',
  //   itemProps: {
  //     label: '医院名称：'
  //   },
  //   component: {
  //     name: 'text'
  //   }
  // },
  /*{
    span: 6,
    kname: 'departmentName',
    itemProps: {
      label: '科室名称：'
    },
    component: {
      name: 'text'
    }
  },*/

  {
    span: 6,
    kname: 'certificateNumber',
    itemProps: {
      label: '执业证书编号：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'approvalTime',
    itemProps: {
      label: '审核时间：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'remark',
    itemProps: {
      label: '备注说明：' },

    component: {
      name: 'text' } },


  {
    kname: 'certificateImages',
    itemProps: {
      label: '执业证书照片：' },

    component: {
      name: 'c-image' } },


  {
    kname: 'qualificationImages',
    itemProps: {
      label: '资格证书照片：' },

    component: {
      name: 'c-image' } }

  /*,
                          {
                           kname: 'contract',
                           itemProps: {
                             label: '上传证书照片：',
                             rules: [genRules('require')]
                           },
                           component: {
                             name: 'upload-file',
                             props: {
                               limit: 3
                             }
                           }
                          }*/];});


export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 100 },

  {
    label: '医院名称',
    kname: 'hospitalName',
    width: 100 },

  {
    label: '科室',
    kname: 'departmentName' },

  {
    label: '地区地址',
    kname: 'address' },

  {
    label: '职称',
    kname: 'jobTitle',
    filterFn: function filterFn(v) {
      return getLabel(jobTitle, v);
    } },

  {
    label: '申请时间',
    kname: 'applicationTime' },

  {
    label: '审核时间',
    kname: 'approvalTime' },


  {
    label: '操作',
    kname: 'btns',
    width: 260 }];};



export var formData = function formData() {return {
    list: [{}, {}] };};